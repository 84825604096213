import hosts from '../hosts';
import request from '..';

function _missingScript(script) {
  throw new Error(`The ${script} script is missing, try to add it to the page and retry.`);
}

export class AuthController {
  static signin(credentials) {
    return request({
      path: '/v1/signin',
      method: 'POST',
      body: credentials,
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static async facebookSignin() {
    const facebookToken = await AuthController.getFacebookToken();
    return request({
      path: '/v1/social-signin',
      method: 'POST',
      body: { facebookToken },
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static signup(credentials) {
    return request({
      path: '/v1/signup',
      method: 'POST',
      body: credentials,
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static async facebookSignup(credentials) {
    const facebookToken = await AuthController.getFacebookToken();
    return request({
      path: '/v1/social-signup',
      method: 'POST',
      body: { facebookToken, ...credentials },
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static signout(refreshToken) {
    if (!refreshToken) return false;
    return request({
      path: '/v1/log-out',
      method: 'POST',
      body: { refreshToken },
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static async requireRefreshedToken(refreshToken) {
    return request({
      path: '/v1/refresh-token',
      method: 'POST',
      body: { refreshToken },
      baseUrl: hosts.AUTH_SERVICE,
    });
  }

  static requireVerificationEmail(userId) {
    return request({
      path: `/users/${userId}/verify-email-request`,
      method: 'POST',
      baseUrl: hosts.MONOLITH,
    });
  }

  static requireVerificationSms(userId) {
    return request({
      path: `/users/${userId}/verify-phone-request`,
      method: 'POST',
      baseUrl: hosts.MONOLITH,
    });
  }

  static requirePasswordReset(email) {
    return request({
      path: '/v1/reset-password-request',
      method: 'POST',
      body: { email },
      baseUrl: hosts.AUTH_SERVICE,
    });
  }

  static async verifyCaptcha(code) {
    const response = await fetch(
      `${hosts.CAPTCHA_SERVICE}/verify?platform=web&captchaCode=${code}`
    );
    const { success } = await response.json();
    return success;
  }

  static getFacebookToken() {
    if (!window.FB) _missingScript('FB');
    return new Promise((resolve, reject) => {
      window.FB.login(
        res => {
          if (res.authResponse) {
            resolve(res.authResponse.accessToken);
          }
          reject(new Error('User cancelled login or did not fully authorize.'));
        },
        { scope: 'email' }
      );
    });
  }

  static getFacebookMail() {
    if (!window.FB) _missingScript('FB');
    return new Promise((resolve, reject) => {
      window.FB.api('/me', { fields: 'email' }, response => {
        if (response.error) {
          reject(new Error(`Impossible to retrieve the user email. ${response.error.message}`));
        } else {
          resolve(response.email);
        }
      });
    });
  }
}
