import jwtDecode from 'jwt-decode';

import { Artist } from 'models/Artist';
import { Bar } from 'models/Bar';
import { Club } from 'models/Club';
import { Event } from 'models/Event';
import { Followed } from 'models/Followed';
import { Restaurant } from 'models/Restaurant';
import { User } from 'models/User';
import { getAccessToken } from 'utils/authTokens';

import hosts from '../hosts';
import request from '..';

const ACTIONS = Object.freeze({
  save: 'POST',
  unsave: 'DELETE',
});

const TYPES = Object.freeze({
  bars: 'bars',
  // clubs: 'venues',
  clubs: 'clubs',
  events: 'events',
  artists: 'artists',
  restaurants: 'restaurants',
});

const MODELS = {
  artists: Artist,
  bars: Bar,
  clubs: Club,
  events: Event,
  restaurants: Restaurant,
};

function _actionNotAllowed(action) {
  throw new Error(`The passed action param "${action}" is not valid.`);
}

export class UserController {
  static getMe = async () => {
    try {
      const user = await request({
        path: '/v1/me',
        baseUrl: hosts.USERS_SERVICE,
      });
      if (!user.paymentAccounts.length) {
        const adyenAccount = await UserController.createPaymentAccount({ userId: user.id });
        user.paymentAccounts.push(adyenAccount);
      }
      const accessToken = getAccessToken();
      const isNightgraphUser = accessToken ? jwtDecode(accessToken).isNightgraphUser : false;
      return new User({ ...user, isNightgraphUser });
    } catch (e) {
      return null;
    }
  };

  static getFollowed = async () => {
    const followed = await request({
      path: `/v1/me/followed`,
      baseUrl: hosts.EVENTS_SERVICE,
    });
    return new Followed(followed);
  };

  static getFollowedByType = async ({ type, ...params }) => {
    const data = await request({
      path: `/v1/me/followed/${TYPES[type]}`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
    });

    return data.map(item => new MODELS[type](item));
  };

  static getMusic = async ({ id }) => {
    return request({
      path: `/v1/users/${id}/music-genres`,
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static getPaymentMethods = async ({ id }) => {
    return request({
      path: `/v1/payment-accounts/${id}/payment-methods`,
      baseUrl: hosts.PAYMENTS_SERVICE,
    });
  };

  static getReviews = () => {
    return request({
      path: '/v1/me/reviews',
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static deletePaymentMethod = async ({ accountId, id }) => {
    return request({
      path: `/v1/payment-accounts/${accountId}/payment-methods/${id}`,
      method: 'DELETE',
      baseUrl: hosts.PAYMENTS_SERVICE,
    });
  };

  static deleteReview = ({ id }) => {
    return request({
      path: `/v1/reviews/${id}`,
      method: 'DELETE',
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static updateMe = userInfo => {
    return request({
      path: `/v1/me`,
      method: 'PATCH',
      baseUrl: hosts.USERS_SERVICE,
      body: userInfo,
    });
  };

  static updateFollowed = ({ type, id, action }) => {
    return request({
      path: `/v1/${TYPES[type]}/${id}/follow`,
      method: ACTIONS[action] || _actionNotAllowed(action),
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static updateMusic = ({ musicGenres: genres }) => {
    const musicGenres = genres.map(({ id }) => id);
    return request({
      path: `/v1/me/music-genres`,
      method: 'PATCH',
      baseUrl: hosts.EVENTS_SERVICE,
      body: { musicGenres },
    });
  };

  static updateImage = file => {
    const reqOptions = {
      path: '/v1/me/profile-image',
      method: file ? 'POST' : 'DELETE',
      baseUrl: hosts.USERS_SERVICE,
    };
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      reqOptions.body = formData;
    }

    return request(reqOptions);
  };

  static updateEmail({ newEmail, ...rest }) {
    return request({
      path: `/v1/me/username`,
      method: 'POST',
      baseUrl: hosts.USERS_SERVICE,
      body: { username: newEmail, ...rest },
    });
  }

  static updatePassword(passwordDetails) {
    return request({
      path: `/v1/update-password`,
      method: 'POST',
      baseUrl: hosts.AUTH_SERVICE,
      body: passwordDetails,
    });
  }

  static updateReview({ id, review }) {
    return request({
      path: `/v1/reviews/${id}`,
      method: 'PATCH',
      baseUrl: hosts.EVENTS_SERVICE,
      body: review,
    });
  }

  static async createPaymentAccount({ userId, paymentPlatform = 'adyen' }) {
    const { paymentAccountId } = await request({
      path: '/v1/payment-accounts',
      method: 'POST',
      baseUrl: hosts.PAYMENTS_SERVICE,
      body: {
        paymentPlatform,
        userId,
      },
    });
    return { userId: paymentAccountId, paymentPlatform: 'adyen' };
  }

  static createReview({ type, id, slug, review }) {
    return request({
      path: `/v1/${TYPES[type]}/${id || slug}/review`,
      method: 'POST',
      baseUrl: hosts.EVENTS_SERVICE,
      body: review,
    });
  }

  static async isUser(queryStrings) {
    const { isUser: userExist } = await request({
      path: `/v1/is-user`,
      baseUrl: hosts.LEGACY_MONOLITH,
      queryStrings,
    });
    return userExist;
  }

  static verifyPhone({ userId, code }) {
    return request({
      path: `/users/${userId}/confirm-phone`,
      baseUrl: hosts.MONOLITH,
      queryStrings: {
        phoneVerificationCode: code,
      },
    });
  }
}
