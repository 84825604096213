import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { AuthController } from 'services/apiClient/interfaces/AuthController';
import { UserController } from 'services/apiClient/interfaces/UserController';
import GoogleTagManager from 'services/GoogleTagManager';
import { useNotify } from 'services/notifications';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from 'utils/authTokens';

const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

const DEFAULT_STATE = {
  loading: false,
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
};

function reducer(state, action) {
  return typeof action === 'function' ? action(state) : { ...state, ...action };
}

export function AuthProvider({ children }) {
  const notify = useNotify();
  const { push } = useRouter();

  const [state, setState] = useReducer(reducer, {
    loading: false,
    isAuthenticated: !!getAccessToken(),
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  });

  const { loading, isAuthenticated, accessToken, refreshToken } = state;

  function _withLoading(func) {
    return async (...args) => {
      setState({ ...state, loading: true });
      try {
        return await func(...args);
      } catch (error) {
        notify.error(error.message || error);
      } finally {
        setState({ ...state, loading: false });
      }
      return null;
    };
  }

  const isUser = _withLoading(UserController.isUser);
  const verifyCaptcha = _withLoading(AuthController.verifyCaptcha);
  const getFacebookMail = _withLoading(AuthController.getFacebookMail);
  const requirePasswordReset = _withLoading(AuthController.requirePasswordReset);

  const removeAuthAccessToken = () => {
    if (state.accessToken) {
      setState({
        ...state,
        accessToken: null,
        isAuthenticated: false,
      });
      removeAccessToken();
    }
  };

  const signOut = async () => {
    await AuthController.signout(refreshToken);
    removeAccessToken();
    removeRefreshToken();
    Cookies.remove('remo', { domain: '.xceed.me' });

    setState({ accessToken: null, refreshToken: null, isAuthenticated: false, isLoading: false });
  };

  const signIn = async ({ type, ...credentials }) => {
    const signinMethods = {
      email: AuthController.signin,
      facebook: AuthController.facebookSignin,
    };
    // eslint-disable-next-line no-return-await
    return await signinMethods[type](credentials);
  };

  const signUp = async ({ type, ...credentials }) => {
    const signupMethods = {
      email: AuthController.signup,
      facebook: AuthController.facebookSignup,
    };
    // eslint-disable-next-line no-return-await
    return await signupMethods[type](credentials);
  };

  useEffect(() => {
    if (!state.accessToken && state.refreshToken) {
      AuthController.requireRefreshedToken(state.refreshToken)
        .then(tokens => {
          setAccessToken(tokens.accessToken);
          setRefreshToken(tokens.refreshToken);
          setState({ ...tokens, isAuthenticated: true, loading: false });
        })
        .catch(() => {
          removeAccessToken();
          removeRefreshToken();
          setState(DEFAULT_STATE);
        });
    }
  }, [state.accessToken, state.refreshToken]);

  const { mutateAsync: signout, isLoading: signoutLoading } = useMutation(signOut, {
    onSuccess: () => {
      setState(DEFAULT_STATE);
      push('/');
    },
  });

  const { mutateAsync: signoutWithoutRedirect, isLoading: signoutwithoutRedirectLoading } =
    useMutation(signOut, {
      onSuccess: () => {
        setState(DEFAULT_STATE);
      },
    });

  const { mutateAsync: signin, isLoading: signinLoading } = useMutation(signIn, {
    onError: error => notify.error(error.message),
    onSuccess: (tokens, { type }) => {
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
      setState({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        isAuthenticated: true,
        isLoading: false,
      });
      const payload = jwtDecode(tokens.accessToken);
      GoogleTagManager.authSuccess({ type: `signup_${type}`, user_id: payload.id });
    },
  });

  const { mutateAsync: signup, isLoading: signupLoading } = useMutation(signUp, {
    onError: error => notify.error(error.message),
    onSuccess: (tokens, { type }) => {
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
      setState({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        isAuthenticated: true,
        isLoading: false,
      });
      const payload = jwtDecode(tokens.accessToken);
      GoogleTagManager.authSuccess({ type: `signup_${type}`, user_id: payload.id });
    },
  });

  const { mutateAsync: verifyPhone, isLoading: verifyPhoneLoading } = useMutation(
    UserController.verifyPhone
  );
  const { mutateAsync: requireVerificationSms, isLoading: requireSmsLoading } = useMutation(
    AuthController.requireVerificationSms
  );
  const { mutateAsync: requireVerificationEmail, isLoading: requireEmailLoading } = useMutation(
    AuthController.requireVerificationEmail
  );

  const isLoading =
    loading ||
    signinLoading ||
    signupLoading ||
    signoutLoading ||
    signoutwithoutRedirectLoading ||
    verifyPhoneLoading ||
    requireSmsLoading ||
    requireEmailLoading;

  const context = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      accessToken,
      refreshToken,
      removeAuthAccessToken,
      signin,
      signup,
      signout,
      signoutWithoutRedirect,
      isUser,
      verifyPhone,
      verifyCaptcha,
      getFacebookMail,
      requirePasswordReset,
      requireVerificationSms,
      requireVerificationEmail,
    }),
    [
      isAuthenticated,
      accessToken,
      refreshToken,
      isLoading,
      removeAuthAccessToken,
      signin,
      signup,
      signout,
      signoutWithoutRedirect,
      isUser,
    ]
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
